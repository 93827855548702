<template>
  <section>
    <v-card>
      <v-card-title>
        <h5>{{$_strings.order.LABEL_STATUS_ORDER}}</h5>
      </v-card-title>
      <v-card-text>
        <section style="margin-left:-40px">
          <v-timeline
            v-for="(order, index) in orderHistories"
            dense
            align-top
            :key="index">
            <v-timeline-item color="blue lighten-2" fill-dot small right>
              <v-row>
                <v-col class="ma-0 pa-0">
                  <p class="grey--text mb-0">
                    {{dateFormat(order.statusDateTime)}}
                  </p>
                  <p class="caption black--text mb-0">{{order.status}}</p>
                  <p class="caption grey--text">{{order.notes}}</p>
                </v-col>
                <v-col cols="auto" class="grey--text">{{timeFormat(order.statusDateTime)}}</v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </section>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
import { dateFormat, timeFormat } from '@/helper/commonHelpers';

export default {
  props: {
    orderHistories: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    dateFormat,
    timeFormat,
  },
};
</script>
